<template>
  <div class="advcost-board">
    <headerNav title="调整详情" :leftIcon="true" @leftClick="back" />
    <div class="record-details">
      <ul>
        <li>
          <span>调整编码</span>
          <span>{{ recordInfo.taskNo }}</span>
        </li>
        <li>
          <span>调整人</span>
          <span>{{ recordInfo.createName }}</span>
        </li>
        <li>
          <span>调整时间</span>
          <span>{{ transverter(recordInfo.createDate) }}</span>
        </li>
      </ul>
      <el-table :data="recordInfo.itemList" header-cell-class-name="table-head-cell">
        <el-table-column prop="name" label="指标" width="118">
          <template slot-scope="scope">
            <span :class="scope.row.type != '0' ? 'text-style change' : 'text-style'">{{ scope.row.name }}{{
              (scope.row.unit && scope.row.name.indexOf('率') == -1 &&
                scope.row.name.indexOf('比例') == -1) ? '(' +
                scope.row.unit + ')' : '' }}</span>
            <van-popover v-model="showHint1" trigger="click" theme="dark" placement="top" :offset="[0, 10]"
              v-if="scope.row.name == '广告费用比例'">
              <div class="hint-text">广告费用占转单产值的比例</div>
              <template #reference>
                <img :src="require('@/assets/a15.svg')" class="hint-icon" alt="">
              </template>
            </van-popover>
            <van-popover v-model="showHint2" trigger="click" theme="dark" placement="top" :offset="[0, 10]"
              v-if="scope.row.name == '有效量'">
              <div class="hint-text">线索有效量</div>
              <template #reference>
                <img :src="require('@/assets/a15.svg')" class="hint-icon" alt="">
              </template>
            </van-popover>
          </template>
        </el-table-column>
        <el-table-column prop="taskNum" label="调整前" align="right">
          <template slot-scope="scope">
            <span :class="scope.row.type != '0' ? 'number-style change' : 'number-style'">{{ [0, 1, 2, 3, 5, 7, 9, 11].includes(scope.$index)?Number(scope.row.taskNum).toFixed(2) : Number(scope.row.taskNum).toFixed(0) }}{{
              scope.row.unit == '%' ? '%' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="realityNum" label="调整后" align="right">
          <template slot-scope="scope">
            <span :class="scope.row.type != '0' ? 'number-style change' : 'number-style'">{{ [0, 1, 2, 3, 5, 7, 9, 11].includes(scope.$index) ? Number(scope.row.realityNum).toFixed(2): Number(scope.row.realityNum).toFixed(0) }}{{
              scope.row.unit == '%' ? '%' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="scale" label="变化" align="right">
          <template slot-scope="scope">
            <span class="change-text" style="color: #FF6157;" v-if="scope.row.type > 0">+{{ [0, 1, 2, 3, 5, 7, 9, 11].includes(scope.$index) ? Number(scope.row.scale).toFixed(2): Number(scope.row.scale).toFixed(0) }}{{
              scope.row.unit == '%' ? '%' : '' }}</span>
            <span class="change-text" style="color: #17CA5E;" v-else-if="scope.row.type < 0">{{ [0, 1, 2, 3, 5, 7, 9, 11].includes(scope.$index) ? Number(scope.row.scale).toFixed(2) : Number(scope.row.scale).toFixed(0) }}{{
              scope.row.unit == '%' ? '%' : '' }}</span>
            <span class="change-text" v-else>-</span>
          </template>
        </el-table-column>
        <div slot="empty" style="text-align: left;">
          <div class="table-empty">
            <img :src="require('@/assets/a-empty.png')">
            <span>暂无数据</span>
          </div>
        </div>
      </el-table>
    </div>
  </div>
</template>

<script>
import api from '../../../api/api'
import moment from 'moment';

export default {
  data () {
    return {
      active: 1,
      recordInfo: [],
      showHint1: false,
      showHint2: false,

    }
  },
  methods: {
    //返回
    back () {
      this.$router.go(-1)
    },
    async getRecordDetails () {
      let obj = {
        id: this.$route.params.id
      }
      let res = await api.getRecordDetails(obj)
      this.recordInfo = res.data
    },
    //日期转文字
    transverter (date) {
      let t = moment(date)

      return (t.month() + 1) + '月' + t.date() + '日' + ' ' + moment(date).format('YYYY-MM-DD HH:mm').split(' ')[1]
    }
  },
  mounted () { },
  created () {
    this.getRecordDetails()
  }
}
</script>

<style scoped lang="scss">
.advcost-board {
  background: #F5F5F5;
  min-height: 100vh;
  background: #F5F5F5;

  .my-tabs {
    margin-top: 1px;
  }

}

.table-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 100px;
  }

  span {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5E5E5E;
  }
}

::v-deep {
  .cell {
    padding: 0 8px;
    &:has(.text-style) {
      padding-right: 0;
    }
    &:has(.number-style){
      padding-left: 0;
    }
    &:has(.change-text){
      padding-left: 0;
    }
  }
}

.hint-icon {
  width: 13px;
  margin-left: 2px;
  margin-bottom: -2px;
}

.hint-popover {
  height: 16px;
  display: flex;
}




.hint-text {
  line-height: 25px;
  padding: 0 10px;
}

.record-details {
  height: calc(100vh - 50px);
  overflow: auto;

  ul {
    padding: 0 12px;
    background: #FFFFFF;

    li {
      line-height: 58px;
      display: flex;
      border-bottom: 1px solid #EBEDF0;

      &:last-child {
        border: none;
      }

      &>span {
        flex: 1;

        &:first-child {
          text-align: left;
          font-size: 17px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #545454;
        }

        &:last-child {
          text-align: right;
          font-size: 19px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }

  .text-style {
    font-size: 13px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #8C8C8C;
  }

  .number-style {
    font-size: 15px;
    font-family: Bahnschrift, Bahnschrift;
    font-weight: normal;
    color: #8C8C8C;
  }

  .change {
    color: #1D2129;
  }

  .change-text {
    font-size: 15px;
    font-family: Bahnschrift, Bahnschrift;
    font-weight: normal;
  }
}
</style>
<style lang="scss">
.table-head-cell {
  background: #F5F5F5 !important;
  font-size: 13px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  color: #1D2129;

  .cell {
    box-sizing: border-box;
  }
}
</style>